.container{
    margin-top: 0 !important;
    padding-top: 0 !important;
   }

   
.inputStyle {
width: 3rem !important;
height: 3rem;
margin: 0 1rem;
font-size: 2rem;
border-radius: 4px;
border: 1px solid rgba(0, 0, 0, 0.3);
}

.margin0Auto {
margin-top: -15% !important;
height: 120% !important;
}

.floatRight {
    float: right !important;
}