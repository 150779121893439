.iconSize {
    font-size: 20px !important;
    padding-left: 4px !important;
}

.pendingIcon {
    font-size: 20px !important;
    padding-left: 4px !important;
    color: #ffc20b;
}

.l5{
    padding-left: 5px;
}

.center  {
    text-align: center;
}

.paddingL97{
    padding-left: 97px;
}
.r14{
    margin-right: 14px;
}

.labelPaddingTop{
    padding-top: 10px;
    font-weight: bold;
    color: gray;
    font-size: 13px;
}

.labelTop{
    font-weight: bold;
    color: gray;
    font-size: 13px;
}


.buttonFloatRight{
    padding-top : 10px; 
    float: right;
}

.floatRightButton{
    float: right;
    margin-right: 20px;
    /* margin-bottom: 10px; */
    font-weight: bold !important;
    /* color: #fff; */
}

.dropDownBtn{
    float: left;
    font-weight: bold !important;
    margin-right: 1%;
}

.searchButton {
    margin-top: 30px;
    margin-right: 9px;
}

.prjBtn {
    float: left;
    margin-left: 8%;
    font-weight: bold !important;
}

.alignItem{
    align-items: center !important;
}

.dropDownBtn .btn{
    font-weight: bold !important;
}

.flexbox-container {
    display: flex;
    flex-direction: row;
}

.hide{
    display: 'none';
}

.MuiBadge-colorPrimary {
    color: #fff;
    background-color: #ffc20b !important;
}

.pendingColor{
    font-size: 23px !important;
    padding-left: 4px !important;
    color: #ffc20b !important;
}

.doneColor {
    font-size: 23px !important;
    padding-left: 4px !important;
    color: #198754d6 !important;
}

.MuiBadge-anchorOriginTopRightRectangle {
    right: auto !important;
    transform: none !important;
    transform-origin: 0% !important;
}

.MuiBadge-colorError {
    color: #fff;
    background-color: #198754d6 !important;
}

.modal-backdrop {
    width: 500vw !important;
    height: 500vh !important;
}

.uploadIcon {
    font-size: 20px;
    padding-bottom: 3px;
}

.displayNone {
    display: none;
}

/* input[type='file'] {
    color: rgba(0, 0, 0, 0)
  } */

  input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.blackColorText{
    color: black !important;
}

.btn-check:focus+.btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0 !important;
}

.btn-outline-success:hover {
    color: #198754 !important;
    background-color: #19875412 !important;
    border-color: #198754 !important;
}

.btn-outline-light:hover {
    color: #fff !important;
    background-color: #b7ecd31c !important;
    border-color: #fff !important;
}

/* .btn-outline-success:hover {
    color: #234e6e !important;
    background-color: #4250fd26 !important;
    border-color: #234e6e !important;
}

.btn-outline-success {
    color: #234e6e !important;
    border-color: #234e6e !important;
} */

.fontSize12{
    font-size: 12px;
}

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
  }
  .pagination > .active > a{
    background-color: #07707e ;
    border-color: #07707e ;
    color: #fff;
  }
  .pagination > li > a{
    border: 1px solid #07707e ;
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #07707e ;
    border-color: #07707e;
    color: white;
    outline: none ;
  }
  .pagination > li > a, .pagination > li > span{
    color: #07707e
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset
  }
