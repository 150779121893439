body {
  margin: 0;
  margin-top:3% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background: rgba(203, 203, 210, 0.15); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "PromptBold";
  src: local("PromptBold"),
   url("./font/Prompt-Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: "PromptRegular";
    src: local("PromptRegular"),
     url("./font/Prompt-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PromptMedium";
  src: local("PromptMedium"),
   url("./font/Prompt-Medium.ttf") format("truetype");
  }

  @font-face {
    font-family: "PromptSemiBold";
    src: local("PromptSemiBold"),
     url("./font/Prompt-SemiBold.ttf") format("truetype");
    }
